@import '@aurora/shared-client/styles/_variables.pcss';
@import '@aurora/shared-client/styles/mixins/_highlight.pcss';

.lia-message {
  display: flex;
}

.lia-section {
  display: flex;
  flex-direction: column;
  flex: 1;
  container-type: inline-size;
  container-name: section-wrap;
}

.lia-left-side {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;

  &:not(.lia-has-vote-only) {
    margin-right: 15px;
  }
  @media (--lia-breakpoint-down-md) {
    &.lia-has-vote-only {
      margin-right: 15px;
    }
  }
  @media (--lia-breakpoint-down-sm) {
    &.lia-left-side {
      margin-right: 10px;
    }
  }
}

.lia-main {
  display: grid;
  grid-template-areas:
    'subject meta'
    'content meta';
  grid-template-columns: 1fr auto;
  row-gap: 5px;

  &.lia-main-compact {
    row-gap: 0;
  }
  &:has(.lia-content:hover) a.lia-subject-link {
    text-decoration: underline;
  }
  @media (--lia-breakpoint-up-lg) {
    &.lia-with-vote {
      grid-template-areas:
        'subject meta'
        'content meta';
    }
  }
}

.lia-subject {
  margin: 0;
  max-width: max-content;
  grid-area: subject;
  font-weight: var(--lia-font-weight-md);
  font-size: var(--lia-bs-font-size-base);
  font-family: var(--lia-bs-font-family-base);
  color: var(--lia-bs-body-color);
  letter-spacing: normal;

  &-xs {
    font-size: var(--lia-bs-font-size-sm);
  }

  .lia-subject-link {
    margin-bottom: 0;
    color: var(--lia-bs-body-color);
  }

  :global {
    .lia-search-match-lithium {
      @include default-highlight();
    }
  }
}

.lia-meta {
  grid-area: meta;
  display: flex;
  align-items: flex-start;
  column-gap: 10px;
}

.lia-content {
  grid-area: content;
  display: block;

  &:hover {
    text-decoration: none;
  }
}

.lia-subtitle {
  display: block;
  margin-bottom: 10px;
  line-height: var(--lia-bs-line-height-base);
  color: var(--lia-bs-gray-800);
}

.lia-body {
  color: var(--lia-bs-body-color);
  word-break: break-word;

  &-md,
  &-sm {
    font-size: var(--lia-bs-font-size-sm);
  }
  &-xs {
    font-size: var(--lia-font-size-xs);
  }
}

.lia-preview-img {
  height: 200px;
  width: 100%;
  max-width: 400px;
  margin: 10px 5px 5px 0;
  object-fit: cover;
  border-radius: var(--lia-bs-border-radius-sm);
}

.lia-footer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 15px;

  .lia-footer-section {
    display: flex;
    margin-top: 5px;
  }
  .lia-footer-left {
    flex-direction: column;
    row-gap: 10px;
  }
  .lia-footer-right {
    justify-content: flex-end;
    align-self: flex-end;
    flex-grow: 1;
    column-gap: 20px;
  }
}

.lia-byline {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 10px;

  &-md {
    font-size: var(--lia-bs-font-size-sm);
  }
  &-sm,
  &-xs {
    font-size: var(--lia-font-size-xs);
  }
}

.lia-byline-item {
  &,
  &:hover,
  &:focus {
    word-break: break-word;
    color: var(--lia-bs-gray-700);
  }
}

.lia-attachment {
  margin-top: 5px;
  font-size: var(--lia-font-size-xs);
  display: flex;

  &-icon,
  &-name {
    color: var(--lia-bs-gray-700);

    :global {
      .lia-search-match-lithium {
        @include default-highlight();
      }
    }
  }

  &-icon {
    flex-shrink: 0;
    margin: 3px 5px 0 0;
  }
}

.lia-node {
  display: flex;
  align-items: center;
  column-gap: 2px;
}

.lia-author-info {
  display: flex;
  align-items: center;
  column-gap: var(--lia-divider-mr);
}

@container section-wrap (min-width: 491px) {
  .lia-main.lia-with-meta {
    column-gap: 10px;
  }
}

@container section-wrap (max-width: 490px) {
  .lia-main {
    grid-template-areas:
      'subject'
      'content';

    &.lia-with-meta:not(.lia-meta-only-vote) {
      grid-template-areas:
        'subject'
        'meta'
        'content';
    }
    &.lia-meta-only-vote .lia-meta {
      display: none;
    }
  }

  .lia-footer .lia-footer-right {
    margin-top: 10px;
    justify-content: flex-start;
    flex-basis: 100%;
  }
}

.lia-solved-badge {
  max-height: 22px;
  margin-top: 2px;
}
